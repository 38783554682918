
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'BlogDetails',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/blog-details/blog-details-4.jpg'),
            },
            {
                id: 2,
                image: require('../../assets/images/blog-details/blog-details-5.jpg'),
            },
            {
                id: 3,
                image: require('../../assets/images/blog-details/blog-details-6.jpg'),
            },
        ],
    }),
})
