
import emailjs from '@emailjs/browser';

export default {
    name: "Navbar",
    data() {
        return {
            isSticky: false,
            search: false,
            button_search_state: false,
            active: false,
            button_active_state: false,
            isSideMenu: false,
        };
    },
    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    methods: {
        isSideMenuMethod(isSideMenu){
            return this.isSideMenu = !isSideMenu
        },

        sendEmail() {
        // this.$refs.recaptcha.execute();
        emailjs.sendForm('service_8z2lfgt', 'template_fldrac9', this.$refs.form, '__6TDFaCcGeP8Hc6o')
        .then((result) => {
            alert("Email sent successfully!");
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
    },
    computed: {
        shoppingCart() {
        return this.$store.state.cart;
        },
    },
};
