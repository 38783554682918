
export default {
    name: 'Projects',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [
                {
                    id: 1,
                    image: require('../../assets/images/projects/SocalExecutiveCarServices.png'),
                    title: 'Socal Executive Car Services',
                    btnLink: 'https://socalexecutivecarservices.com/',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 2,
                    image: require('../../assets/images/projects/HomesByKonjan.png'),
                    title: 'Homes By Konjan',
                    btnLink: 'https://homesbykonjan.com/',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 3,
                    image: require('../../assets/images/projects/KidsLearningPath.png'),
                    title: 'Kids Learning Path',
                    btnLink: 'https://kidslearningpath.com/',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 4,
                    image: require('../../assets/images/projects/NorthshoreLearningCenter.png'),
                    title: 'Northshore Learning Center',
                    btnLink: 'https://northshorelearningcenter.com/',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 5,
                    image: require('../../assets/images/projects/Zeeni.png'),
                    title: 'Zeeni',
                    btnLink: 'https://zeeni.com/',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 6,
                    image: require('../../assets/images/projects/AffordableTechRepair.png'),
                    title: 'Affordable Tech Repair',
                    btnLink: 'https://affordabletechrepair.com/',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 7,
                    image: require('../../assets/images/projects/FreeSpree.png'),
                    title: 'FreeSpree',
                    btnLink: '#',
                    btnText: 'View More',
                    key: 'mobile',
                },
            ],
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        mobile(){
            return this.items.filter((item) => item.key == 'mobile')
        },
        development(){
            return this.items.filter((item) => item.key == 'development')
        },
        web(){
            return this.items.filter((item) => item.key == 'web')
        },
        product(){
            return this.items.filter((item) => item.key == 'product')
        },
    }
}
