
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Services',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/services/services-1.jpg'),
                title: 'Software Development',
                desc: 'Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…',
                link: '/services-details',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/services/services-2.jpg'),
                title: 'App Development',
                desc: 'Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…',
                link: '/services-details',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/services/services-3.jpg'),
                title: 'Web Development',
                desc: 'Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…',
                link: '/services-details',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/services/services-4.jpg'),
                title: 'Analytic Solutions',
                desc: 'Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…',
                link: '/services-details',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
