
import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
// import ChooseUs from '../ServicesOne/ChooseUs'
import Services from '../ServicesOne/Services'
import Services2 from '../ServicesOne/Services2'
import Talk from '../Common/Talk'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'ServicesPageOne',
    components: {
        Topbar,
        Navbar,
        PageBanner,
        // ChooseUs,
        Services,
        Services2,
        Talk,
        Overview,
        Footer,
    }
}
