
import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Courses from '../Courses/Courses'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'CoursesGridPage',
    components: {
        Topbar,
        Navbar,
        PageBanner,
        Courses,
        Overview,
        Footer,
    }
}
