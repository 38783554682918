
import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import AppDevelopment from '../ServicesDetails/AppDevelopment'
import Talk from '../Common/Talk'
import Services from '../ServicesDetails/Services'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'
import AppDevelopmentProjects from '../RecentProjects/AppDevelopmentProjects.vue'

export default {
    name: 'ServicesDetailsPage',
    components: {
    Topbar,
    Navbar,
    PageBanner,
    AppDevelopment,
    AppDevelopmentProjects,
    Talk,
    Services,
    Overview,
    Footer,
}
}
