
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MainBanner',
    components: {
        Carousel,
        Slide,
        // Pagination,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                subtitle: 'Let’s talk IT',
                heading: 'The leading technology providing company with cutting-edge technology',
                description: 'We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor.',
                image: require('../../assets/images/home-slides/slides-1.png'),
                btnLink: '/contact',
                btnText: 'Get Started'
            },
            // {
            //     id: 1,
            //     subtitle: 'WE ARE LEADING TECHNOLOGY SOLUTIONS PROVIDING COMPANY',
            //     heading: 'Best IT Services For Your Business',
            //     description: 'We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor.',
            //     image: require('../../assets/images/home-slides/slides-2.png'),
            //     btnLink: '/contact',
            //     btnText: 'Get Started'
            // },
        ],
    }),
})
