
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Projects',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/projects/projects-1.jpg'),
                title: '3D Animation',
                btnLink: '/projects-details',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/projects/projects-2.jpg'),
                title: 'Online Banking Software',
                btnLink: '/projects-details',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/projects/projects-3.jpg'),
                title: 'Cashier Software',
                btnLink: '/projects-details',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/projects/projects-4.jpg'),
                title: 'Analytics Software',
                btnLink: '/projects-details',
                btnText: 'View More',
            },
            {
                id: 5,
                image: require('../../assets/images/projects/projects-5.jpg'),
                title: 'Messaging App',
                btnLink: '/projects-details',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1024: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
        },
    }),
})
