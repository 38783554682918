
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Partner',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/projects/projects-1.jpg'),
                imageHover: require('../../assets/images/projects/projects-1.jpg'),
            },
            {
                id: 2,
                image: require('../../assets/images/projects/projects-2.jpg'),
                imageHover: require('../../assets/images/projects/projects-2.jpg'),
            },
            {
                id: 3,
                image: require('../../assets/images/projects/projects-3.jpg'),
                imageHover: require('../../assets/images/projects/projects-3.jpg'),
            },
            {
                id: 4,
                image: require('../../assets/images/projects/projects-4.jpg'),
                imageHover: require('../../assets/images/projects/projects-4.jpg'),
            },
            // {
            //     id: 5,
            //     image: require('../../assets/images/projects/projects-5.jpg'),
            //     imageHover: require('../../assets/images/projects/projects-5.jpg'),
            // },
        ],
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
