
export default {
    name: 'Projects',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [
                {
                    id: 1,
                    image: require('../../assets/images/services/services-3.jpg'),
                    title: 'Web Development',
                    desc: 'Our experience and expertise in web design and development would…',
                    btnLink: '/web-development',
                    btnText: 'View More',
                    
                },
                {
                    id: 2,
                    image: require('../../assets/images/services/services-2.jpg'),
                    title: 'Mobile App Development',
                    desc: 'Our expert team promises you to deliver cutting-edge mobile app development…',
                    btnLink: '/app-development',
                    btnText: 'View More',
                },
                {
                    id: 3,
                    image: require('../../assets/images/services/services-8.jpg'),
                    title: 'SEO & SEM',
                    desc: 'Organic Search, Paid Search, Brand Campaigns, Digital Advertising…',
                    btnLink: '/seo-and-sem',
                    btnText: 'View More',
                },
                {
                    id: 4,
                    image: require('../../assets/images/services/services-7.jpg'),
                    title: 'Big Data & AI',
                    desc: 'We help our clients to explore the advantages and opportunities in big data…',
                    btnLink: '/big-data-and-ai',
                    btnText: 'View More',
                },
                {
                    id: 5,
                    image: require('../../assets/images/services/services-5.jpg'),
                    title: 'Machine Learning',
                    desc: 'Experience our machine learning services with advanced algorithms to…',
                    btnLink: '/machine-learning',
                    btnText: 'View More',
                },
                {
                    id: 6,
                    image: require('../../assets/images/services/services-4.jpg'),
                    title: 'Analytic Solutions',
                    desc: 'Brand Strategy, Growth Strategy, Digital Strategy, Culture Strategy, Channel…',
                    btnLink: '/analytic-solutions',
                    btnText: 'View More',
                },
                
                
            ],
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        mobile(){
            return this.items.filter((item) => item.key == 'mobile')
        },
        development(){
            return this.items.filter((item) => item.key == 'development')
        },
        web(){
            return this.items.filter((item) => item.key == 'web')
        },
        product(){
            return this.items.filter((item) => item.key == 'product')
        },
    }
}
