
import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Faq from '../Faq/Faq'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'FaqPage',
    components: {
        Topbar,
        Navbar,
        PageBanner,
        Faq,
        Overview,
        Footer,
    }
}
