
export default {
    name: 'Topbar',
	data: () => ({
		show: false,
		active: false,
        button_show_state: false,
		newData: [
            {
                id: '',
                image: require('../../assets/images/uk.png'),
                name: 'ENG',
            },
            {
                id: '',
                image: require('../../assets/images/china.png'),
                name: '简体中文',
            },
            {
                id: '',
                image: require('../../assets/images/uae.png'),
                name: 'العربيّة',
            }
        ],
		name: "ENG",
		image: require("../../assets/images/uk.png"),
        clicked: false
	}),

    methods: {
        toggleSelected(select, index){
            if(this.clicked === index){
                this.clicked = null
            }

            this.clicked = index 
            this.name = select.name 
            this.image = select.image
        }
    }
}
