
import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import AnalyticSolutions from '../ServicesDetails/AnalyticSolutions'
import Talk from '../Common/Talk'
import Services from '../ServicesDetails/Services'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'
// import AnalyticSolutionsProjects from '../RecentProjects/AnalyticSolutionsProjects.vue'

export default {
    name: 'ServicesDetailsPage',
    components: {
    Topbar,
    Navbar,
    PageBanner,
    AnalyticSolutions,
    // AnalyticSolutionsProjects,
    Talk,
    Services,
    Overview,
    Footer,
}
}
