
import CartItem from './CartItem'

export default {
    name: 'Cart',
    components: {
        CartItem
    },
    computed: {
        shoppingCart() {
            return this.$store.state.cart
        },
        totalAmount() {
          return this.$store.getters.total
        }
    }
}
