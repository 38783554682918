
import ProductsItem from './ProductsItem'

export default {
    name: 'Products',
    components: {
        ProductsItem
    },
    methods: {
        updateCart(e) {
            this.cart.push(e);
            this.total = this.shoppingCartTotal;
        },
        emptyCart() {
            this.cart = [];
            this.total = 0;
        },
    },
    computed: {
        products() {
            return this.$store.state.items;
        },
    },
}
