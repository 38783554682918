
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Projects',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/projects/SocalExecutiveCarServices.png'),
                title: 'Socal Executive Car Services',
                btnLink: 'https://socalexecutivecarservices.com/',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/projects/HomesByKonjan.png'),
                title: 'Homes By Konjan',
                btnLink: 'https://homesbykonjan.com/',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/projects/KidsLearningPath.png'),
                title: 'Kids Learning Path',
                btnLink: 'https://kidslearningpath.com/',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/projects/NorthshoreLearningCenter.png'),
                title: 'Northshore Learning Center',
                btnLink: 'https://northshorelearningcenter.com/',
                btnText: 'View More',
            },
            {
                id: 5,
                image: require('../../assets/images/projects/Zeeni.png'),
                title: 'Zeeni',
                btnLink: 'https://zeeni.com/',
                btnText: 'View More',
            },
            {
                id: 6,
                image: require('../../assets/images/projects/AffordableTechRepair.png'),
                title: 'Affordable Tech Repair',
                btnLink: 'https://affordabletechrepair.com/',
                btnText: 'View More',
            },
            {
                id: 7,
                image: require('../../assets/images/projects/FreeSpree.png'),
                title: 'FreeSpree',
                btnLink: '#',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3.1,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4.1,
                snapAlign: 'center',
            },
        },
    }),
})
