
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Blog',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/blog/blog-1.jpg'),
                date: '9th July, 2022',
                title: 'How Technology Dominate In The new World In 2022',
                desc: 'Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.',
                btnLink: '/single-blog-1',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/blog/blog-2.jpg'),
                date: '9th July, 2022',
                title: 'Top 10 Most Famous Technology Trend In 2022',
                desc: 'Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.',
                btnLink: '/single-blog-1',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/blog/blog-3.jpg'),
                date: '9th July, 2022',
                title: 'How Technology Dominate In The new World In 2022',
                desc: 'Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.',
                btnLink: '/single-blog-1',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/blog/blog-4.jpg'),
                date: '9th July, 2022',
                title: 'Top 10 Most Famous Technology Trend In 2022',
                desc: 'Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.',
                btnLink: '/single-blog-1',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'left',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
        },
    }),
})
