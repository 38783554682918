
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Services',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/services/services-3.jpg'),
                title: 'Web Development',
                desc: 'Our experience and expertise in web design and development…',
                link: '/web-development',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/services/services-2.jpg'),
                title: 'Mobile App Development',
                desc: 'Our expert team promises you to deliver cutting-edge mobile…',
                link: '/app-development',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/services/services-8.jpg'),
                title: 'SEO & SEM',
                desc: 'Organic Search, Paid Search, Brand Campaigns, Digital Advertising, Social…',
                link: '/seo-and-sem',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/services/services-7.jpg'),
                title: 'Big Data & AI',
                desc: 'We help our clients to explore the advantages and opportunities in big…',
                link: '/big-data-and-ai',
                btnText: 'View More',
            },
            {
                id: 5,
                image: require('../../assets/images/services/services-5.jpg'),
                title: 'Machine Learning',
                desc: 'Experience our machine learning services with advanced…',
                link: '/machine-learning',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3.1,
                snapAlign: 'center',
            },
        },
    }),
})
