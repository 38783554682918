
export default {
    name: 'Projects',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [
                {
                    id: 1,
                    image: require('../../assets/images/projects/projects-1.jpg'),
                    title: '3D Animation',
                    btnLink: '/projects-details',
                    btnText: 'View More',
                    key: 'mobile',
                },
                {
                    id: 2,
                    image: require('../../assets/images/projects/projects-2.jpg'),
                    title: 'Online Banking Software',
                    btnLink: '/projects-details',
                    btnText: 'View More',
                    key: 'development',
                },
                {
                    id: 3,
                    image: require('../../assets/images/projects/projects-3.jpg'),
                    title: 'Cashier Software',
                    btnLink: '/projects-details',
                    btnText: 'View More',
                    key: 'product',
                },
                {
                    id: 4,
                    image: require('../../assets/images/projects/projects-4.jpg'),
                    title: 'Analytics Software',
                    btnLink: '/projects-details',
                    btnText: 'View More',
                    key: 'development',
                },
                {
                    id: 5,
                    image: require('../../assets/images/projects/projects-5.jpg'),
                    title: 'Messaging App',
                    btnLink: '/projects-details',
                    btnText: 'View More',
                    key: 'web',
                },
                {
                    id: 6,
                    image: require('../../assets/images/projects/projects-6.jpg'),
                    title: 'Banking Software',
                    btnLink: '/projects-details',
                    btnText: 'View More',
                    key: 'mobile',
                },
            ],
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        mobile(){
            return this.items.filter((item) => item.key == 'mobile')
        },
        development(){
            return this.items.filter((item) => item.key == 'development')
        },
        web(){
            return this.items.filter((item) => item.key == 'web')
        },
        product(){
            return this.items.filter((item) => item.key == 'product')
        },
    }
}
