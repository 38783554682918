
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'left',
        },
        carouselItems: [
            {
                id: 1,
                desc: 'We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.',
                image: require('../../assets/images/testimonials/testimonials-4.jpg'),
                name: 'Bradly Doe',
                position: 'Founder of Medizo',
            },
            {
                id: 2,
                desc: 'The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Koze Team.',
                image: require('../../assets/images/testimonials/testimonials-5.jpg'),
                name: 'Daniel John',
                position: 'Solit Team',
            },
            {
                id: 3,
                desc: 'We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.',
                image: require('../../assets/images/testimonials/testimonials-6.jpg'),
                name: 'Jennifer Smith',
                position: 'Spix Team',
            },
            {
                id: 4,
                desc: 'The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Koze Team.',
                image: require('../../assets/images/testimonials/testimonials-7.jpg'),
                name: 'Sarp Karahan',
                position: 'Benzo Team',
            },
            {
                id: 5,
                desc: 'We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.',
                image: require('../../assets/images/testimonials/testimonials-8.jpg'),
                name: 'Jane Ronan',
                position: 'Lebu Team',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'left',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1024: {
                itemsToShow: 3.1,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 4.1,
                snapAlign: 'left',
            },
        },
    }),
})
