
import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import WebDevelopment from '../ServicesDetails/WebDevelopment'
import Talk from '../Common/Talk'
import Services from '../ServicesDetails/Services'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'
import WebDevelopmentProjects from '../RecentProjects/WebDevelopmentProjects.vue'

export default {
    name: 'ServicesDetailsPage',
    components: {
    Topbar,
    Navbar,
    PageBanner,
    WebDevelopment,
    WebDevelopmentProjects,
    Talk,
    Services,
    Overview,
    Footer,
    
}
}
