
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MainBanner',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                subtitle: 'WE ARE LEADING TECHNOLOGY SOLUTIONS PROVIDING COMPANY',
                heading: 'We Are Digital Agency & Tech Solution',
                description: 'We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor.',
                btnLink: '/contact',
                btnText: 'Get Started',
                class: 'main-hero-item',
            },
            {
                id: 1,
                subtitle: 'WE ARE LEADING TECHNOLOGY SOLUTIONS PROVIDING COMPANY',
                heading: 'Excellent IT Services For Your Success',
                description: 'We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor.',
                btnLink: '/contact',
                btnText: 'Get Started',
                class: 'main-hero-item item-bg-2',
            },
            {
                id: 1,
                subtitle: 'WE ARE LEADING TECHNOLOGY SOLUTIONS PROVIDING COMPANY',
                heading: 'Get The Best IT Solution From Us',
                description: 'We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor.',
                btnLink: '/contact',
                btnText: 'Get Started',
                class: 'main-hero-item item-bg-3',
            },
        ],
    }),
})
