
export default {
    name: 'Projects',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [
                {
                    id: 1,
                    image: require('../../assets/images/projects/FreeSpree.png'),
                    title: 'FreeSpree',
                    btnLink: '#',
                    btnText: 'View More',
                    
                },
                // {
                //     id: 2,
                //     image: require('../../assets/images/projects/SocalExecutiveCarServices.jpg'),
                //     title: 'Socal Executive Car Services',
                //     btnLink: 'https://socalexecutivecarservices.com/',
                //     btnText: 'View More',
                // },
                // {
                //     id: 3,
                //     image: require('../../assets/images/projects/SocalExecutiveCarServices.jpg'),
                //     title: 'Project3',
                //     btnLink: '/',
                //     btnText: 'View More',
                // },
                
                
                
            ],
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        mobile(){
            return this.items.filter((item) => item.key == 'mobile')
        },
        development(){
            return this.items.filter((item) => item.key == 'development')
        },
        web(){
            return this.items.filter((item) => item.key == 'web')
        },
        product(){
            return this.items.filter((item) => item.key == 'product')
        },
    }
}
