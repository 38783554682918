
import emailjs from '@emailjs/browser';
// import { VueRecaptcha } from 'vue-recaptcha';
export default {
    // components: { VueRecaptcha },
    name: 'Talk',
    methods: {
    sendEmail() {
        // this.$refs.recaptcha.execute();
      emailjs.sendForm('service_8z2lfgt', 'template_fldrac9', this.$refs.form, '__6TDFaCcGeP8Hc6o')
        .then((result) => {
            alert("Email sent successfully!");
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
  }
}
