
export default {
    name: 'ProductsDetails',
    data() {
        return {
            itemNumber: 1,
        }
    },
    methods: {
        increment(){
            if(this.itemNumber < 1){
                this.itemNumber++
            } else {
                this.$toast.success('Can\'t add more than 1 items', {
                    icons: 'shopping_cart',
                    theme: "bubble", 
                    position: "bottom-right", 
                    duration : 3000
                });
            }
        },
        decrement(){
            if(this.itemNumber > 1){
                this.itemNumber--
            } else {
                this.$toast.success('Can\'t add less than 1 item', {
                    icons: 'shopping_cart',
                    theme: "bubble",
                    position: "top-right",
                    duration : 3000
                });
            }
        },
        addToCart(item) {
            this.$toast.success('Added to cart', {
                icons: 'shopping_cart',
                position: "bottom-left", 
                duration : 3000
            });
            if(this.size !== '') {
                this.$store.commit({
                    type: 'addToCart',
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    oldPrice: item.oldPrice,
                    image: item.image,
                    productQuantity: this,
                    totalPrice: item.price,
                })
            }
        }
    }
}
