
export default {
    name: 'CartItem',
    props: ['item'],
    data() {
        return {
            itemNumber: 1,
        }
    },
    methods: {
        removeFromCart(item){
            this.$toast.success('Removed from bag', {
                icons: 'shopping_cart',
                theme: "bubble", 
                position: "bottom-left", 
                duration : 3000
            });
            this.$store.commit('removeFromCart', item);
        },
        increment(){
            if(this.itemNumber < 10){
                this.itemNumber++
            } else {
                this.$toast.success('Can\'t add more than 10 items', {
                    icons: 'shopping_cart',
                    theme: "bubble", 
                    position: "bottom-right", 
                    duration : 3000
                });
            }
        },
        decrement(){
            if(this.itemNumber > 1){
                this.itemNumber--
            } else {
                this.$toast.success('Can\'t add less than 1 item', {
                    icons: 'shopping_cart',
                    theme: "bubble",
                    position: "top-right",
                    duration : 3000
                });
            }
        },
    }
}
