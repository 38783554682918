
export default {
    name: 'Checkout',
    data(){
        return{
            personDetails: {
                firstName: 'John', 
                lastName: 'Doe',
                companyName: 'ABC',
                address: 'Town hall, av 02',
                townCity: 'New York',
                stateCountry: 'America',
                postcodeZip: '1234',
                emailAddress: 'john@gmail.com',
                phone: '+990198378372',
                createdAt: new Date()
            }
        }
    },
    methods: {
        placeOrder(){
            this.$toast.success('Thanks for the order', {
                icons: 'shopping_cart',
                theme: "bubble", 
                position: "bottom-left", 
                duration : 3000
            });

            this.$store.state.cart = [];
            this.$router.push("/")
        }
    },
    computed: {
        shoppingCart() {
            return this.$store.state.cart
        },
        totalAmount() {
          return this.$store.getters.total
        }
    }
}
