
export default {
    name: "NavbarStyleTwo",
    data() {
        return {
            isSticky: false,
            search: false,
            button_search_state: false,
            active: false,
            button_active_state: false,
            isSideMenu: false,
        };
    },
    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    methods: {
        isSideMenuMethod(isSideMenu){
            return this.isSideMenu = !isSideMenu
        },
    },
    computed: {
        shoppingCart() {
        return this.$store.state.cart;
        },
    },
};
